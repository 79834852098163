<template>
  <div>
    <Menu :loginInfo="loginInfo" />
    <h2 class="my-4">초대 링크 관리</h2>
    <div v-if="urlNew" class="row" :class="{ blink: !!urlNew }">
      <div class="col">
        <div class="alert alert-info">새 링크를 생성했습니다: https://t.me/econoti_bot?start={{ urlNew }}</div>
      </div>
    </div>
    <div v-if="urlDeleteOk" class="row" :class="{ blink: urlDeleteOk }">
      <div class="col">
        <div class="alert alert-info">추천 링크를 삭제했습니다.</div>
      </div>
    </div>
    <table class="table table-striped align-middle text-center">
      <thead>
        <tr>
          <th scope="col">
            <input type="checkbox" class="form-check-input" />
          </th>
          <th scope="col">초대 링크 주소</th>
          <th scope="col">클릭 수</th>
          <th scope="col">모집 성공</th>
          <th scope="col">삭제</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="url of urlList" :key="url.url">
          <td>
            <input type="checkbox" class="form-check-input" />
          </td>
          <td>https://t.me/econoti_bot?start={{ url.url }}</td>
          <td>{{ url.click }}</td>
          <td>{{ url.success }}</td>
          <td><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal" @click="urlDeleteTarget = url.url">삭제</button></td>
        </tr>
      </tbody>
    </table>
    <div class="row mb-5">
      <div class="col text-center">
        <button type="button" class="btn btn-primary" @click="urlCreateClick">새 링크 생성</button>
      </div>
    </div>
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">추천 링크 삭제</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            https://t.me/econoti_bot?start={{ urlDeleteTarget }} 추천링크를 삭제하시겠습니까?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="urlDeleteClick">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blink {
  animation: blink-animation 0.3s steps(5, start) 4;
}
@keyframes blink-animation {
  to {
    opacity: 0.4;
  }
}
</style>

<script>
import { reactive, ref, onMounted } from 'vue';
import ko from 'date-fns/locale/ko';
import Menu from '@/components/Menu.vue';

export default {
  components: {
    Menu
  },
  props: {
    urlPage: String
  },
  setup(props) {
    const locale = ko;
    const loginInfo = reactive({});
    const urlList = reactive([]);
    const urlNew = ref(null);
    const urlDeleteTarget = ref(null);
    const urlDeleteOk = ref(false);

    const urlListProc = async (res) => {
      if(await loginCheck(loginInfo, res)) return;
      urlList.length = 0;
      urlList.push(...res.urlList);
      return res;
    };

    (async () => {
      try{
        urlListProc(await backend({ action: 'urlList' }));
      }catch(e){
        console.log(e);
      }
    })();

    onMounted(() => {
      document.getElementById("modal").addEventListener("hidden.bs.modal", () => {
        window.scrollTo(0, 0);
      });
    });
    const urlDeleteClick = async () => {
      try{
        let res = await urlListProc(await backend({ action: 'urlDelete', url: urlDeleteTarget.value }));
        if(!res.isOk) return;
        urlDeleteOk.value = false;
        setTimeout(() => urlDeleteOk.value = true, 0);
      }catch(e){
        console.log(e);
      }
    };

    const urlCreateClick = async () => {
      try{
        let res = await urlListProc(await backend({ action: 'urlCreate' }));
        if(!res.url) return;
        urlNew.value = null;
        setTimeout(() => urlNew.value = res.url, 0);
        window.scrollTo(0, 0);
      }catch(e){
        console.log(e);
      }
    };

    return { locale, loginInfo, urlNew, urlList, urlDeleteTarget, urlDeleteOk, urlDeleteClick, urlCreateClick };
  }
}
</script>
